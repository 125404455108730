<template>
  <div>
    <router-link
      v-for="project in appConfig"
      :key="project.projectName"
      :to="{ name: `project-${project.projectName}` }"
    >
      <BButton>{{ project.title }}</BButton>
    </router-link>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import appConfig from '@core/app-config'

export default {
  components: {
    BButton,
  },

  data() {
    return {
      appConfig,
    }
  },
}
</script>

<style>
button {
  margin-right: 1rem;
}
</style>
